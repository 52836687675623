import React from 'react'
import { 
  GoogleMap, 
  withScriptjs, 
  withGoogleMap, 
  Marker
} from 'react-google-maps' 
import './GoogleMaps.css'

function Map() {
  return (
    <GoogleMap 
      defaultZoom={ 15 } 
      defaultCenter={{ lat: 43.76843, lng: -79.41313 }}
    >
      <Marker position={{ lat: 43.76843, lng: -79.41313 }}/>
    </GoogleMap>
  )
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

function GoogleMaps() {
  return (
    <div className="google-maps-wrapper">
        <WrappedMap 
          googleMapURL={ "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBS66wWWBtEgoWAS1J988vn7AQ2tULP5LA" }
          loadingElement={ <div className="google-maps-element-height" /> }
          containerElement={ <div className="google-maps-element-height" /> }
          mapElement={ <div className="google-maps-element-height" /> }
        />
    </div>

  )
}

export default GoogleMaps
