import React, { useState, useEffect } from 'react'
import './Blog.css'
import moment from 'moment'
import BlogList from '../../components/BlogList'
import blogBanner from '../img/blog-banner.png'

import db from '../../firebase'
import {    
    collection,
    getDocs
} from 'firebase/firestore'

const Blog = () => {

    document.title = 'Blog';
    document.description = 'Our thoughts on the latest developments in DBA technologies and news. Here we will periodically post useful articles and documents pertaining to relevant solutions that you may need.';

    const [posts, setPosts] = useState(null);
    useEffect (() => {
        // Fetch list of blog posts from database
        const fetchPosts = async () => {
            const querySnapshot = await getDocs(collection(db, 'blog-test'));
            const temp = [];
            querySnapshot.forEach((doc) => {
                temp.push({ ...doc.data(), id: doc.id });
            })


            setPosts(sortPosts(temp));
        }

        const sortPosts = (temp) => {
            const sorted = temp.sort((a, b) => {
                return moment(b.date).diff(a.date);
            });
            return sorted;
        }


        fetchPosts();
    }, [])

    return (
        <div className='blog-container-main'>
            <div className="blog-banner-container">
                <img className="blog-banner" alt={ blogBanner } src={ blogBanner } />
                <div className="blog-shifting-container">
                    <div className="blog-banner-text">
                            <div className="blog-banner-half-span">
                                <h1>Blog Page</h1>
                                <p>
                                    Our thoughts on the latest developments in DBA technologies and news. Here we will periodically post useful
                                    articles and documents pertaining to relevant solutions that you may need.
                                    
                                </p>
                            </div>                        
                        </div>
                </div>
            </div>
            <div className="blog-shifting-container">
                
                {/* <div className="blog-column-wrapper">
                    <div className="blog-column-container">
                        { posts?.map(post => (
                            <BlogPost
                                author={ post.author }
                                date={ post.date }
                                img={ post.img }
                                summary={ post.summary }
                                title={ post.title }
                                url={ post.url }
                                key={ post.id }
                            />
                        ))}
                    </div>
                </div> */}

                <div className='blog-post-list'>
                    { posts?.length === 1 ? <h2>No posts yet!</h2> : <div /> }
                    { posts?.map(post => (
                        post.id === 'base-doc' ? 
                        <div key='base-doc' />
                        :
                        <BlogList
                            author={ post.author }
                            date={ post.date }
                            hasDoc= {post.hasDoc }
                            img={ post.img }
                            summary={ post.summary }
                            title={ post.title }
                            url={ post.url }
                            key={ post.id }
                            id={ post.id }
                        />
                    ))}
                </div>
                
            </div>
        
        </div>
    )
}

export default Blog