import React from 'react'
import './Services.css'
import { NavLink } from 'react-router-dom'
import Marquee from '../components/MarqueeScroll/MarqueeScroll'

import servicesBanner from './img/services-banner2.png'
import assessment from './img/Assessment_icon2.png'
import remoteDB from './img/Remote_DB_icon2.png'
import consulting from './img/Consulting_icon2.png'

function Services() {
    document.title = 'Remote DBA Support and Services';
    document.description = 'Our consulting services can help you get more out of your database applications — Oracle, SQL Server, and MySQL — whether it be increasing operational efficiencies in your system, or enhancing current database security auditing.';

    return (
        <div className="services-container-main">
            <div className="services-banner-container">
                <img className="services-banner" src={ servicesBanner } alt={ servicesBanner } />
                <div className="services-shifting-container">
                    <div className="services-banner-text">
                        <div className="services-banner-half-span">
                            <h1>Remote DBA Support and Services</h1>
                            <p>
                                Our consulting services can help you get more out of your database 
                                applications — Oracle, SQL Server, and MySQL — whether it be increasing operational efficiencies 
                                in your system, or enhancing current database security auditing.
                                
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="services-shifting-container">
                <h2 className="services-column-title">Our services include:</h2>
                <div className="services-column-wrapper">
                    <div className="services-column-container">
                        <div className="services-column">
                            <img className="services-icon" src={ assessment } alt="assessment" />
                            <h3>DATABASE ENVIRONMENT ASSESSMENT</h3>
                            <ul className="services-list">
                                <li key="db0">System Health Check and Audit Services</li>
                                <li key="db1">Server Consolidation</li>
                                <li key="db2">Database Performance and Scalability Assessment</li>
                                <li key="db3">Backup and Replication Reliable Solution Delivery</li>
                                <li key="db4">Express IT team training</li>
                            </ul>
                        </div>
                        <div className="services-column">
                            <img className="services-icon" src={ remoteDB } alt="remoteDB" />
                            <h3>REMOTE DB MANAGEMENT</h3>
                            <ul className="services-list">
                                <li key="rem0">24/7 Oprational DBA Support</li>
                                <li key="rem1">Oracle OEM Monitoring and Management</li>
                                <li key="rem2">Real-time Database Monitoring and Alerting</li>
                                <li key="rem3">Proactive Daily, Weekly, Monthly Maintenance and Optimization</li>
                                <li key="rem4">Database Troubleshooting and Resolution</li>
                            </ul>
                        </div>
                        <div className="services-column">
                            <img className="services-icon" src={ consulting } alt="consulting" />
                            <h3>DATABASE CONSULTING</h3>
                            <ul className="services-list">
                                <li key="con0">Database Infrastructure Best Practice Evaluation</li>
                                <li key="con1">Performance Tuning and Optimization</li>
                                <li key="con2">Database Installation, Upgrading and Migration</li>
                                <li key="con3">High Availability (RAC) and Disaster Recovery (DataGuard) Solution</li>
                                <li key="con4">Scalability and Compliance Assessment</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="services-spacer" />
                <div className="services-para">
                    <h2>Why Customers Choose Us</h2>
                    <p>
                    Our unique experience gives us 25 years of practice in many large high profile 
                    corporations and governments, excellent track records, and 
                    highly reliable, trouble-free database service production. Customers choose 
                    us because we are able to give them tailored advice 
                    for design challenges using our decades of experience solving complex 
                    database issues.
                    </p>
                    <div className="services-para-link">
                        <p className="p-inline">To get started now, </p>
                        <NavLink to="/contact">
                            <p className="inline-link">contact SunCan Communications »</p>
                        </NavLink>
                    </div>
                </div>
                
            </div>
            <Marquee />
            <script> </script>
        </div>
    )
}

export default Services;
