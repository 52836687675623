import React from 'react'
import './client-pages.css'
import TableMenu from '../../components/TableMenu/TableMenu'
import DropDown from '../../components/DropDown/DropDown'

const data = require("./clientData");
const dataKeyed = Object.values(data[0]);

const categoryGen = () => {
    
    const path = window.location.pathname;
    const path_category = path.replace("/clients/category/", "");

    function renderTablesAll() {
        let tablesAll = [];
        if (path_category === "") {
            tablesAll.push(
                <div>
                    <h1>Customers by Category</h1>
                    <div className="tables-spacer"></div>
                    <DropDown />
                    <div className="tables-spacer"></div>
                </div>
            );

            for (let i = 0; i < dataKeyed.length; i++) {
                let dataImport = dataKeyed[i];
                tablesAll.push(
                    <div className="tables-wrapper">
                        <h2>{ dataImport.name }</h2>
                        <table className="tables-collapse">
                            <thead>
                                <tr>
                                    <th className="tables-head-left">Company</th>
                                    <th className="tables-head-right">Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                { renderTableItems(dataImport) }
                            </tbody>
                        </table>
                        <div className="tables-spacer"></div>
                    </div>
                    
                );
            }
        } else {
            // if statement to prevent an undefined error from being thrown because
            // this page's code runs again when pressing the back button and the 
            // /clients path causes an error with the path_category variable
            if (path_category === "/clients") {
                return tablesAll;
            }
            
            let dataImport = data[0][path_category];
            tablesAll.push(
                <div className="tables-wrapper">
                    <h1>Customers in { dataImport.name }</h1>
                    <div className="tables-spacer"></div>
                    <DropDown />
                    <table className="tables-collapse">
                        <thead>
                            <tr>
                                <th className="tables-head-left">Company</th>
                                <th className="tables-head-right">Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderTableItems(dataImport) }
                        </tbody>
                    </table>
                    <div className="tables-spacer"></div>
                </div>
            );
        }

        return tablesAll;
    }
    
    function renderTableItems(dataImport) {
        let result = [];
        for (let i = 0; i < dataImport.clients.length; i++) {
            if (dataImport.href[i] === "none") {
                result.push(
                    <tr>
                        <td className="tables-logo-border">
                            <img className="tables-logo" src={ dataImport.icon[i] } alt={ dataImport.icon[i] }>
                            </img>
                        </td>
                        <td className="tables-company">
                            { dataImport.clients[i] }
                        </td>
                        
                    </tr>
                )
            } else {
                result.push(
                    <tr>
                        <td className="tables-logo-border">
                            <img className="tables-logo" src={ dataImport.icon[i] } alt={ dataImport.icon[i] }>
                            </img>
                        </td>
                        <td className="tables-company">
                            <a href={ dataImport.href[i] } target="_blank" rel="noopener noreferrer">{ dataImport.clients[i] }</a>
                        </td>
                        
                    </tr>
                )
            }
        }
        return result;
    }

    return (
        <div className="tables-container-main">
            <TableMenu />
            <div className="tables-wrapper">
            { renderTablesAll() }
            <div className="tables-spacer"></div>
            </div>
        </div>
    )
}

export default categoryGen
