import './App.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Contact from './pages/Contact';
import CategoryGen from './pages/Client-pages/categoryGen';
import CountryGen from './pages/Client-pages/countryGen';
import Blog from './pages/Blog-pages/Blog'
import Privacy from './pages/Legal/Privacy';
import Terms from './pages/Legal/Terms';
import Admin from './pages/Admin'
import PostPage from './pages/Blog-pages/PostPage'

function usePageViews() {
  	let location = useLocation();
  	useEffect(() => {
    	if (!window.GA_INITIALIZED) {
      		ReactGA.initialize("UA-206098864-1");
    		window.GA_INITIALIZED = true;
    	}
    	ReactGA.set({ page: location.pathname });
    	ReactGA.pageview(location.pathname);
  	}, [location]);
}

// Check when page is resized and shut off animations and transitions while resizing
let resizeTimer;
window.addEventListener("resize", () => {
  	document.body.classList.add("resize-animation-stopper");
  	clearTimeout(resizeTimer);
  	resizeTimer = setTimeout(() => {
    	document.body.classList.remove("resize-animation-stopper");
  	}, 400);
});

function App() {
	usePageViews();
	return (
		<BrowserRouter>
			<AuthProvider>
				<div className="App">
					<div className="content-wrap">
						<Navbar />
						<Route exact path="/" component={Home} />
						<Route exact path="/services" component={Services} />
						<Route exact path="/clients" component={Clients} />
						<Route strict path="/clients/category/" component={CategoryGen} />
						<Route strict path="/clients/country/" component={CountryGen} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/blog" component={Blog} />
						<Route exact path="/privacy-policy" component={Privacy} />
						<Route exact path="/terms-conditions" component={Terms} />
						<Route exact path="/admin" component={Admin} />
						<Route strict path="/post/" component={PostPage} />
					</div>
					<Footer />
				</div>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;