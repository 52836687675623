import React from 'react'
import './Contact.css'
import GoogleMaps from '../components/GoogleMaps/GoogleMaps'
import ContactForm from '../components/ContactForm/ContactForm';


function Contact() {

    document.title = 'Contact Us';
    document.description = 'Contact Suncan Communications Corporation today!';

    return (
        <div className="contact-container-main">
            <div className="contact-shifting-container">
                <div className="contact-title-wrapper">
                    <h1>Contact Us</h1>
                </div>
                <div className="contact-location-container">
                    <div className="contact-location-text-wrapper">
                        <h2>Office Information </h2>
                        <p>Email: <a href = "mailto: info@remote-dba.ca">info@remote-dba.ca</a></p>
                        <p>Phone: +1 (416) 917-2940</p>
                        <p>Fax: +1 (647) 347-8294</p>
                        <p>Address:&nbsp;
                            <a href="https://www.google.com/maps/place/5140+Yonge+St,+North+York,+ON+M2N+6L7,+Canada/@43.7684693,-79.413274,17z/data=!4m5!3m4!1s0x882b2d6e30eac12d:0xd86e967e9921a4e3!8m2!3d43.7684306!4d-79.4131292" target="_blank" rel="noopener noreferrer">14th FL, 5140 Yonge Street, Toronto, Ontario, Canada M2N 6L7</a>
                        </p>
                    </div>
                    <div className="contact-location-map">
                        <GoogleMaps/>
                    </div>
                </div>
                <p className="contact-inquiry-message">For general inquires, please email us at&nbsp;
                    <a href = "mailto: info@remote-dba.ca">info@remote-dba.ca</a>
                    , or fill out the contact form below.
                </p>
            </div>
            <div className="contact-form-container">
                <div className="contact-shifting-container-row">
                    <ContactForm />                    
                    <div className="contact-form-description">
                        <h2 className="contact-form-description-title">How Can We Help?</h2>
                        <p className="contact-form-description-content">Submit a contact request through this form and we will get back to you as soon as possible.</p>
                        <div className="contact-form-description-row">
                            <h3>Consultation Services</h3>
                            <p>Request consultation from our experienced specialists.</p>
                        </div>
                        <div className="contact-form-description-row">
                            <h3>Database Management</h3>
                            <p>Find out how you can manage a more efficient, high performance database.</p>
                        </div>
                        <div className="contact-form-description-row-bottom">
                            <h3>Enhanced Security</h3>
                            <p>Have peace of mind when you use our contingency plan and risk management systems.</p>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    )
}

export default Contact;