import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import clientData from '../../pages/Client-pages/clientData'
import './DropDown.css'

const dataKeyed = Object.values(clientData[0]);

class DropDownCountry extends Component {

    getCountryList = () => {
      let countryList = [];
      for (let i = 0; i < dataKeyed.length; i++) {
          countryList = countryList.concat([...new Set(dataKeyed[i].country)]);
      }
      countryList = [...new Set(countryList)];
      countryList = countryList.sort();
      return countryList;
    }

    renderOptions = () => {
        let options = [];
        let countryList = this.getCountryList();
        
        for (let i = 0; i < countryList.length; i++) {
            let url_path = "clients/country/" + countryList[i];
            options.push(
                <option value={ url_path }>
                    { countryList[i] }
                </option>
            );
        }
        
        return options;
    }

    onChange = (e) => {
      this.props.history.push(`/${e.target.value}`);
    }

    render() {
      return (
        <select className="option-font" onChange={this.onChange}>
          { this.renderOptions() }
        </select>
      );
    }
}

const Menu = withRouter(DropDownCountry)
export default Menu;