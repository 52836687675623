export const MenuItems = [
    {
        label: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        label: 'Services',
        url: '/services',
        cName: 'nav-links'
    },
    {
        label: 'Clients',
        url: '/clients',
        cName: 'nav-links'
    },
    {
        label: 'Blog',
        url: '/blog',
        cName: 'nav-links'
    },
    {
        label: 'Contact',
        url: '/contact',
        cName: 'nav-links'
    }
]