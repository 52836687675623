import React from 'react'
import './TableMenu.css'
import { NavLink } from 'react-router-dom'

const data = require("../../pages/Client-pages/clientData");
const dataKeyed = Object.values(data[0]);

const Table_menu = () => {

    function getCountries() {
        let countries = [];
        let temp = [];
        for (let i = 0; i < dataKeyed.length; i++) {
            temp = dataKeyed[i].country;
            countries = countries.concat(temp);  
        }
        countries = Array.from(new Set(countries));
        countries = countries.sort();
        return countries;
    }

    function renderCountries() {
        let countryList = getCountries();
        let href = "";
        let countryLinks = [];

        for (let i = 0; i < countryList.length; i++) {
            href = "/clients/country/" + countryList[i];
            countryLinks.push(
                <NavLink className="table-links" to={ href }>
                    <li className="table-list" key={ i }>{ countryList[i] }</li>
                </NavLink>
            )
        }
        return countryLinks;
    }

    function renderCategories() {
        let categories = [];

        for (let i = 0; i < dataKeyed.length; i++) {
            categories.push(
                <NavLink className="table-links" to={ dataKeyed[i].link }>
                    <li className="table-list" key={ i }>{ dataKeyed[i].name }</li>
                </NavLink>
                
            )
        }
        return categories;
    }

    return (
        <nav className="left-container">
            <h3 className="table-title">Sort by Category</h3>
            <ul className="no-bullet-list">
                { renderCategories() }
                <NavLink className="table-links" to="/clients/category/">
                    <li className="table-list" key="all">See All</li>
                </NavLink>
            </ul>
            <h3 className="table-title">Sort by Country</h3>
            <ul className="no-bullet-list">
                { renderCountries() }
                <NavLink className="table-links" to="/clients/country/">
                    <li className="table-list" key="all">See All</li>
                </NavLink>
            </ul>
        </nav>
    )
}

export default Table_menu;