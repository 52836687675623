import React from 'react';
import { NavLink } from 'react-router-dom'
import './Footer.css';
import partner2 from '../Footer/oracle-gold-transparent-noletter.png'

function Footer () {

    return (
        <footer className="Footy">
            <div className="foot-logo-group">
                <div className="foot-company-logo">
                    <NavLink className="foot-company-logo-name" to="/">
                        <h1 className="foot-company-logo-title">Suncan Communications</h1>
                    </NavLink>
                    <NavLink className="foot-company-logo-name" to="/">C O R P O R A T I O N</NavLink>
                    <img className="foot-partner" src={ partner2 } alt="Oracle Gold Partner" />
                </div> 
            </div>
            <a className="fab fa-linkedin" href="https://www.linkedin.com/company/suncan-communications-corp-" target="_blank" rel="noopener noreferrer"> </a>
            <p className="copyright">
                Copyright © Suncan Communications Corporation 2020. All rights reserved.
            </p>
            <div className="foot-legal-group">
                <div className="foot-legal-col1">
                    <a className="foot-link1" href="/privacy-policy">Privacy Policy</a>
                </div>
                <div className="foot-legal-col2">
                    <a className="foot-link2" href="/terms-conditions">Terms and Conditions</a>
                </div>
                
            </div>
            
        </footer>
    )
}

export default Footer;