// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDs_8LVRW337dlaV_kk0TsilLq78j8iOhI",
  authDomain: "suncan-cc-database.firebaseapp.com",
  projectId: "suncan-cc-database",
  storageBucket: "suncan-cc-database.appspot.com",
  messagingSenderId: "582905968949",
  appId: "1:582905968949:web:106e1a4c788c825fe3f47c",
  measurementId: "G-WE51TZGDQ0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);



export default db;