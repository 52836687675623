import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import clientData from '../../pages/Client-pages/clientData'
import './DropDown.css'

const dataKeyed = Object.values(clientData[0]);

class DropDown extends Component {

    renderOptions = () => {
        let options = [];
        
        for (let i = 0; i < dataKeyed.length; i++) {
            //console.log(dataKeyed[i].link.substring(1));
            options.push(
                <option value={ dataKeyed[i].link.substring(1) }>
                    { dataKeyed[i].name }
                </option>
            );
        }
        
        return options;
    }

    onChange = (e) => {
      this.props.history.push(`/${e.target.value}`);
    }

    render() {
      return (
        <select className="option-font" onChange={this.onChange}>
          { this.renderOptions() }
        </select>
      );
    }
  }

const Menu = withRouter(DropDown)
export default Menu;