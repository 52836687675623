import React from 'react'
import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toast = ({ message }) => {
    toast.success(message);

    console.log("toast success");
    return (
        <div>
            <ToastContainer 
                hideProgressBar={ true }
                limit={ 1 }
                transition={ Slide }
                autoClose={ 3000 }
                position={ toast.POSITION.TOP_CENTER}
            />
        </div>
    )
}

export default Toast
