import React from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItems } from "./MenuItems"
import './Navbar.css'
import "../ScrollToTop"

import partner from './oracle-gold.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'


class Navbar extends React.Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    // Make the dropdown menu disappear when navigating to another page
    menuClick = () => {
        this.setState({ clicked: false })
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    scrollToContact = () => {
        window.scrollTo(0, 660);
    }

    
    render() {
        return(
            <div className="nav-container"> 
                <div className="nav-header">
                    <div className="nav-left-group">
                        <div className="nav-logo-container" onClick={ this.scrollToTop }>
                            <NavLink className="nav-logo-name" to="/">
                                <h1 className="navbar-logo">Suncan Communications</h1>
                            </NavLink>
                            <NavLink className="nav-logo-name" to="/">C O R P O R A T I O N</NavLink>
                        </div>
                        <img className="nav-partner" src={ partner } alt="Oracle Gold Partner" />
                    </div>
                    <div className="menu-icon" onClick={this.handleClick}>
                        <FontAwesomeIcon 
                            icon={this.state.clicked ? faXmark : faBars}
                            className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}
                        />
                    </div>
                    <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                        {MenuItems.map((item, index) => {
                            return (
                                <li className="nav-menu-links"key={index}>
                                    <NavLink className={item.cName} exact to={item.url} activeClassName="nav-links-active" onClick={() => { this.menuClick(); this.scrollToTop();}}>
                                    {item.label}
                                    </NavLink>
                                </li>
                            )
                        })} 
                        <NavLink to="/contact">
                            <button className="nav-button" onClick={() => { this.menuClick(); this.scrollToContact();}}>Get Started »</button>
                        </NavLink>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navbar;