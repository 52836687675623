import React from 'react'
import './Home.css'
import Marquee from '../components/MarqueeScroll/MarqueeScroll'
import { Link } from 'react-router-dom'

// Image Imports
import homeBanner from './img/home-banner.png'
import oracleLogo from './img/Oracle2.png'
import mysqlLogo from './img/MySQL2.png'
import sqlserverLogo from './img/sqlserver2.png'
import boxContent4 from './img/box-content-4.png'
import boxContent3 from './img/box-content-3.png'
function Home() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    document.title = 'Enterprise Database Consulting Services';
    document.description = 'Suncan Communications Corporation is an enterprise databse consulting service that has spent over 25 years serving businesses in Canada and the US.'

    return (
        <div className="home-container-main">
            <div className="home-banner-container">
                <img className="home-banner" src={ homeBanner } alt="banner" />
                <div className="home-banner-text">
                    <h1>Enterprise Database Consulting Services</h1>
                    <p>Over 25 years serving businesses in Canada and the United States.</p>
                    <Link to="/contact">
                        <button className="home-marquee-button" onClick={ scrollToTop }>Contact Us »</button>
                    </Link>
                </div>
            </div>
            <Marquee />
            <hr className="home-border-bottom" />
            <div className="home-para-long">
                <h2>Experts in leading database consulting services</h2>
                <p>
                Our expertise in large size, high transaction volume database management, capacity planning, performance tuning
                and upgrade/migration, backup/recovery solutions make us a trusted partner with our clients across the globe. 
                Adapting to your database applications, software, and needs is our specialty!
                </p>
            </div>
            <Link to="/services">
                    <button className="home-marquee-button" onClick={ scrollToTop }>Learn More »</button>
            </Link>
            <div className="home-box-content">
                <div className="home-shifting-container-reverse">      
                    <div className="home-box-text-wrapper">
                        <h2>Receive practiced consulting expertise</h2>
                        <i className="fas fa-user" />
                        <i className="fas fa-handshake" />
                        <h3>Over 25 years of experience</h3>
                        <p>
                            With over 25 years spent serving businesses, you can trust
                            our consulting will guide you to the database solution you
                            are looking for
                        </p>
                    </div>
                    <img className="home-box-img" src={ boxContent4 } alt={ boxContent4 } />
                </div>
                <hr className="home-spacer" />
                <div className="home-shifting-container">
                    <img className="home-box-img" src={ boxContent3 } alt={ boxContent3 } />
                    <div className="home-box-text-wrapper2">
                        <h2>Help for your DBAs</h2>
                        <i className="fas fa-database" />
                        <i className="fas fa-cloud" />
                        <h3>We work side by side with you</h3>
                        <p>
                            Upgrades and cloud migrations can require expert help and we are here
                            to provide that for you. Plan, consult, and prepare with our team so 
                            you can be ready for your next database project
                        </p>
                    </div>
                </div>
            </div>
            <h2 className="home-img-logo-title">Our core skill set covers these technologies:</h2>
            <div className="home-shifting-container">
                <div className="home-img-trio">
                    <div className="home-img-logo-container">
                        <img className="home-img-logo-oracle" src={ oracleLogo } alt={ oracleLogo } />
                    </div>
                    <div className="home-img-logo-container">
                        <img className="home-img-logo-mysql" src={ mysqlLogo } alt={ mysqlLogo } />
                    </div>
                    <div className="home-img-logo-container">
                        <img className="home-img-logo-sqlserver" src={ sqlserverLogo } alt={ sqlserverLogo } />
                    </div>
                </div>
            </div>
            <p className="home-img-logo-text">We are here to support you when you need us most!</p>
            <hr className="home-spacer" />
        </div>
    )
}

export default Home;
