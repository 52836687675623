import React, { useRef, useState }from 'react'
import useForm from './useForm'
import validate from './validateInfo'
import Toast from '../Toast/Toast'
import './ContactForm.css'
import emailjs from 'emailjs-com'

const ContactForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const submitForm = () => {
        setIsSubmitted(true);
        sendEmail();
    }

    const { handleChange, values, handleSubmit, errors } = useForm(submitForm, validate);
    const form = useRef();

    const sendEmail = (e) => {
    
        emailjs.sendForm('service_ikbdbhb', 'template_6wmsizn', form.current, 'user_cU1z3hdHlrVOPLMIlOtUk')
          .then((result) =>{ 
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });  
          
          setIsSubmitted(true);
    };
    
    return (
        <div className="form-container">
            <form className="form" ref={ form } success={ isSubmitted.toString() } onSubmit={ handleSubmit }>
                { !isSubmitted ? <div></div> : <Toast message="Submitted Successfully!"/> }

                <div className="form-inputs">      
                    <input 
                        id="company"
                        type="text" 
                        name="company" 
                        className="form-input"
                        value={ values.company }
                        onChange={ handleChange }
                        required
                    />
                    <label htmlFor="company" className="form-label">
                        Company
                    </label>
                    { errors.company && <p>{ errors.company }</p> }
                    
                </div>
                <div className="form-row">
                    <div className="form-inputs">
                        <input 
                            id="name1"
                            type="name1" 
                            name="name1" 
                            className="form-input"
                            value={ values.name1 }
                            onChange={ handleChange }
                            required
                        />
                        <label htmlFor="name1" className="form-label">
                            First Name
                        </label>
                        { errors.name1 && <p>{ errors.name1 }</p> }
                    </div>
                    <div className="form-inputs">
                        <input 
                            id="name2"
                            type="name2" 
                            name="name2" 
                            className="form-input"
                            value={ values.name2 }
                            onChange={ handleChange }
                            required
                        />
                        <label htmlFor="name2" className="form-label">
                            Last Name
                        </label>
                        { errors.name2 && <p>{ errors.name2 }</p> }
                    </div>
                </div>
                
                <div className="form-inputs">
                    <input 
                        id="email"
                        type="text" 
                        name="email" 
                        className="form-input"
                        value={ values.email }
                        onChange={ handleChange }
                        required
                    />
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    { errors.email && <p>{ errors.email }</p> }
                </div>
                {/* <div className="form-inputs">
                    <input 
                        id="phone"
                        type="number"
                        name="phone"
                        className="form-input-number"
                        value={ values.phone }
                        onChange={ handleChange }
                        required
                    />
                    <label htmlFor="phone" className="form-label">
                        Phone
                    </label>
                    { errors.phone && <p>{ errors.phone }</p> }
                </div> */}

                <div className="form-inputs">
                    <input 
                        id="phone"
                        type="number"
                        name="phone"
                        className="form-input-number"
                        value={ values.phone }
                        onChange={ handleChange }
                        required
                    />
                    <label htmlFor="phone" className="form-label">
                        Phone
                    </label>
                    { errors.phone && <p>{ errors.phone }</p> }
                </div>

                <div className="form-inputs">
                    <label htmlFor="message" className="form-label-message">
                        Message (Optional)
                    </label>
                    <textarea 
                        id="message"
                        type="message" 
                        name="message" 
                        className="form-input-message"
                        value={ values.message }
                        onChange={ handleChange }
                    />
                </div>
                <button className="form-input-btn" type="submit">Submit »</button>
            </form>
            
        </div>
    )
}

export default ContactForm
