export default function validateInfo(values) {
    let errors = {}

    if (!values.company.trim()) {
        errors.company = "Company required"
    }

    if (!values.email) {
        errors.email = "Email required"
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    if (!values.name1.trim()) {
        errors.name1 = "First name required"
    }

    if (!values.name2.trim()) {
        errors.name2 = "Last name required"
    }

    if (!values.phone.trim()) {
        errors.phone = "Phone number required"
    } else if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(values.phone)) {
        errors.phone = "Phone number is invalid";
    }


    return errors
}