import React from 'react'
import './client-pages.css'
import TableMenu from '../../components/TableMenu/TableMenu'
import DropDownCountry from '../../components/DropDown/DropDownCountry'

const data = require("./clientData");
const dataKeyed = Object.values(data[0]);

const countryGen = () => {

    const path = window.location.pathname;
    let path_country = path.replace("/clients/country/", "");

    function renderTablesAll() {
        let tablesAll = [];
        let countryList = getCountryList();
        if (path_country === "") {
            tablesAll.push(
                <div>
                    <h1>Customers by Country</h1>
                    <div className="tables-spacer"></div>
                    <DropDownCountry />
                    <div className="tables-spacer"></div>
                </div>
            )
            for (let i = 0; i < countryList.length; i++) {
                path_country = countryList[i];
                tablesAll.push(
                    <div className="tables-wrapper">
                        <h2>{ path_country }</h2>
                        <table className="tables-collapse">
                            <thead>
                                <tr>
                                    <th className="tables-head-left">Company</th>
                                    <th className="tables-head-right">Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                { renderTableItems(path_country) }
                            </tbody>
                        </table>
                    <div className="tables-spacer"></div>
                </div>
            );
            }
        } else {
            if (path_country.includes("%20")) {
                path_country = path_country.replace("%20", " ")
            }
            tablesAll.push(
                <div>
                    <h1>Customers in { path_country }</h1>
                    <div className="tables-spacer"></div>
                    <DropDownCountry />
                    <table className="tables-collapse">
                        <thead>
                            <tr>
                                <th className="tables-head-left">Company</th>
                                <th className="tables-head-right">Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderTableItems(path_country) }
                        </tbody>
                    </table>
                    <div className="tables-spacer"></div>
                </div>
            );
        }
        return tablesAll;
    }

    function getCountryList() {
        let countryList = [];
        for (let i = 0; i < dataKeyed.length; i++) {
            countryList = countryList.concat([...new Set(dataKeyed[i].country)]);
        }
        countryList = [...new Set(countryList)];
        countryList = countryList.sort();
        return countryList;
    }

    function renderTableItems(path_country) {
        let result = [];
        let coordinates = generateCoordinates(path_country);

        for (let i = 0; i < coordinates.length; i++) {
            let current_coordinate = coordinates[i];

            if (dataKeyed[current_coordinate[0]].href[current_coordinate[1]] === "none") {
                result.push(
                    <tr>
                        <td className="tables-logo-border">
                            <img className="tables-logo" src={ dataKeyed[current_coordinate[0]].icon[current_coordinate[1]] } alt={ dataKeyed[current_coordinate[0]].icon[current_coordinate[1]] }>
                            </img>
                        </td>
                        <td className="tables-company">
                            { dataKeyed[current_coordinate[0]].clients[current_coordinate[1]] }
                        </td>
                    </tr>
                );
            } else {
                result.push(
                    <tr>
                        <td className="tables-logo-border">
                            <img className="tables-logo" src={ dataKeyed[current_coordinate[0]].icon[current_coordinate[1]] } alt={ dataKeyed[current_coordinate[0]].icon[current_coordinate[1]] }>
                            </img>
                        </td>
                        <td className="tables-company">
                        <a href={ dataKeyed[current_coordinate[0]].href[current_coordinate[1]] } target="_blank" rel="noopener noreferrer">{ dataKeyed[current_coordinate[0]].clients[current_coordinate[1]] }</a>
                        </td>
                    </tr>
                );
            }

            
        }
        return result;
    }

    function generateCoordinates(path_country) {
        let coordinates = [];
        for (let i = 0; i < dataKeyed.length; i++) {
            for (let j = 0; j < dataKeyed[i].country.length; j++) {
                if (dataKeyed[i].country[j] === path_country) {
                    coordinates.push([i, j]);
                }
            }
        } 
        return coordinates;
    }

    return (
        <div className="tables-container-main">
            <TableMenu />
            <div className="tables-wrapper">
                        { renderTablesAll() }
            <div className="tables-spacer"></div>
            </div>
        </div>
    )
}

export default countryGen
