import React from 'react';
import './MarqueeScroll.css';

//const LOGOS_PER_LOOP = 10; // Max 31 (half of total # of icons)

const data = require("../../pages/Client-pages/clientData.json");
const dataKeyed = Object.values(data[0]);

const MarqueeScroll = () => {

    const iconRandomizer = () => {
        let iconList = [];
        
        // Traverse through client data and grab each icon link
        for (let i = 0; i < dataKeyed.length; i++) {
            iconList = iconList.concat(dataKeyed[i].icon);
        }
        
        // ES6 implementation of Durstenfeld shuffle for array randomization
        for (let i = iconList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [iconList[i], iconList[j]] = [iconList[j], iconList[i]];
        }

        return iconList;
    }

    // Call iconRandomizer once so for the 2 calls of logoGen, it uses the same random list
    const index = iconRandomizer();
    const half = Math.ceil(index.length / 2);
    const list1 = index.slice(0, half);
    const list2 = index.slice(-half);

    const logoGen = (list1, list2, logosPerLoop, key) => {
        let result =  [];

        // Duplicate one of the icons if there is an odd total number so an error isn't thrown
        if (list1.length !== list2.length) {
            list2.push(list2[list2.length/2]);
        }

        for (let i = 0; i < logosPerLoop; i++) {
            result.push(
                // Pass in parameter from each call of this function to generate a unique key
                // for each created div (which acts as a list item)
                <div className="marquee-img" key={ key + i }>
                    <img className="marquee-logo-top" src={ list1[i] } alt={ list1[i] } />
                    <img className="marquee-logo-bottom" src={ list2[i] } alt={ list2[i] } />
                </div>
            )

        }
        return result;
    }

    return (
        <div className="marquee-container">
            <div className="marquee-logos-wrapper-10">
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 10, "top10-") }
                </div>
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 10, "bot10-") }
                </div>
            </div>
            <div className="marquee-logos-wrapper-8">
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 8, "top8-") }
                </div>
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 8, "bot8-") }
                </div>
            </div>
            <div className="marquee-logos-wrapper-5">
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 5, "top5-") }
                </div>
                <div className="marquee-logos-set">
                    { logoGen(list1, list2, 5, "bot5-") }
                </div>
            </div>
        </div>          
    )
}

export default MarqueeScroll
