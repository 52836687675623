import React from 'react';
import './Clients.css';
import { NavLink } from 'react-router-dom';

import clientsBanner from './img/clients-banner2.png'

const ITEMS_PER_ROW = 3;
const ITEMS_PER_LIST = 3;
const ITEMS_PER_ROW_MOBILE = 2;
const data = require("./Client-pages/clientData");
const dataKeyed = Object.values(data[0]);



const Clients = () => {

    document.title = 'Clients and Customers';
    document.description = 'Suncan Communications Corporation provides essential solutions to services in a wide variety of industries. Both new and existing clients can contact us at any time of the day and expect a swift reply with the next steps to proceed with addressing whatever server and database operations need work.';

    function renderClientList(n_row, key) {
        let tableList = [];

        // Divide the number of categories by the items per row and round up to ensure
        // there are enough rows for all content
        for (let i = 0; i < Math.ceil(dataKeyed.length / n_row); i++) {
            tableList.push(
                <tr key={ key + " row-" + i }>
                    {renderRow(i, n_row)}
                </tr>
            );
        }
        return tableList;
    }

    function renderRow(row, n_row) {
        let list = [];
        let range = n_row * row + n_row;

        // If there is a remainder (meaning there will be a row that's not full) AND
        // if renderRow is currently on the last row (not full), then change for loop
        // range to end at the last index in the list
        if (((dataKeyed.length % n_row) !== 0) && (row === Math.ceil(dataKeyed.length / n_row) - 1)) {
            range -= n_row - (dataKeyed.length % n_row);
        }
        for (let i = (n_row * row); i < range; i++) {
            list.push(
                    <td className="clients-table-cell" key={ "row-" + i }>
                        <NavLink className="clients-category-link" to={ dataKeyed[i].link }>
                            <h3 className="clients-category">
                                { dataKeyed[i].name.toUpperCase() }
                            </h3>
                        </NavLink>
                        <ul className="clients-list">{ checkClients(i) }</ul>
                    </td>    
            );
            
        }
        return list;
    }

    // Checks if the current category length is within the parameter, and then determines
    //  whether to use the random index generator to randomly display clients, and 
    // includes a More link that routes to the category table
    function checkClients(count) {
        let clients = [];
        let index = [];

        // If length of current category's client list is less or equal to the items per
        // list parameter, then don't use random generation and just print the clients
        if (dataKeyed[count].clients.length <= ITEMS_PER_LIST) {
            index = Array.from(Array(ITEMS_PER_LIST).keys());
            clients = renderClients(count, index);
        } 
        // Use random index generation to get 3 random clients for display
        else {
            index = randomGen(ITEMS_PER_LIST, dataKeyed[count].clients.length);
            clients = renderClients(count, index);
        }
        
        clients.push(
            <li className="clients-bullet" key={ "more" + count }>
                <NavLink className="clients-client" to={ dataKeyed[count].link }>
                    MORE
                </NavLink>
            </li>);
        return clients;
    }

    // Renders the HTML links for n items in the client list
    function renderClients(count, index) {
        let result = [];

        for (let i = 0; i < ITEMS_PER_LIST; i++) {
            let currentIndex = index[i];
            if (dataKeyed[count].href[currentIndex] === "none") {
                result.push(
                    <li className="clients-bullet" key={ "client-" + count + "-" + i }>
                        { dataKeyed[count].clients[currentIndex] }
                    </li>
                );
            } else {
                result.push(
                    <li className="clients-bullet" key={ "client-" + count + "-" + i }>
                        <a className="clients-client" href={ dataKeyed[count].href[currentIndex] } target="_blank" rel="noopener noreferrer">
                        { dataKeyed[count].clients[currentIndex] }
                        </a>
                    </li>
                );
            }
        }
        return result;
    }

    // Generate n unique random numbers to use as indices for the displayed clients in 
    // the list
    function randomGen(n, range) {
        let rand = [];

        for (let i = 0; i < n; i++) {
            let r = Math.floor(Math.random() * range);
            if (!rand.includes(r)) {
                rand.push(r);
            } else {
                i--;
            }
        }
        return rand;
    }

    // Render the 3 components that make the the banner header above the table
    function renderHeader() {
        let header = [];
        let index = [];
        let clientIndex = [];

        // Generate 3 random category indices, then generate 3 random client indices
        // within each category
        index = randomGen(ITEMS_PER_ROW, dataKeyed.length);
        for (let i = 0; i < index.length; i++) {
            let tempIndex = index[i];
            clientIndex.push(randomGen(1, dataKeyed[tempIndex].clients.length));
        }

        // Concatenate the 2 dimensional array into a 1 dimensional array
        for (let i = 0; i < clientIndex.length; i++) {
            clientIndex[i] = clientIndex[i][0];
        }

        header.push(
            <thead className="clients-header-container" key="header-desktop">
                <tr key="header-category">
                    { renderHeaderCategory(index) }
                </tr>
                <tr key="header-img">
                    { renderHeaderImg(index, clientIndex) }
                </tr>
                <tr key="header-client">
                    { renderHeaderClient(index, clientIndex) }
                </tr>
            </thead>
        );
        return header;
    }

    // Render the category for the component
    function renderHeaderCategory(index) {
        let headerCategory = [];

        // Use random indices to write category names
        for (let i = 0; i < ITEMS_PER_ROW; i++) {
            let category = index[i];
            headerCategory.push(
                <th className="clients-header" key={ "header-category-" + i }>
                    <p className="clients-header-category">
                        <NavLink className="clients-no-decor" to={ dataKeyed[category].link }>
                            { dataKeyed[category].name }
                        </NavLink>
                    </p>
                    
                </th>
            );
        }
        return headerCategory;
    }

    // Render the image for the component
    function renderHeaderImg(index, clientIndex) {
        let headerImg = [];

        for (let i = 0; i < ITEMS_PER_ROW; i++) {
            let tempIndex = index[i];
            let tempClient = clientIndex[i];

            if (i !== 0 && i !== ITEMS_PER_ROW - 1) {
                headerImg.push(
                    <td className="clients-header-cell-border" key={ "header-img-" + i }>
                        <img className="clients-header-img" src={ dataKeyed[tempIndex].icon[tempClient] } alt={ dataKeyed[tempIndex].icon[tempClient] }></img>
                    </td>
                ); 
            } else {
                headerImg.push(
                    <td className="clients-header-cell" key={ "header-img-" + i }>
                        <img className="clients-header-img" src={ dataKeyed[tempIndex].icon[tempClient] } alt={ dataKeyed[tempIndex].icon[tempClient] }></img>
                    </td>
                );
            }
        }
        return headerImg;
    }

    // Render the client name for the component
    function renderHeaderClient(index, clientIndex) {
        let headerClient = [];

        for (let i = 0; i < ITEMS_PER_ROW; i++) {
            let tempIndex = index[i];
            let tempClient = clientIndex[i];

            if (i !== 0 && i !== ITEMS_PER_ROW - 1) {
                headerClient.push(
                    <td className="clients-header-client-cell-border" key={ "header-client-" + i }>
                        <p className="clients-header-client">
                            { dataKeyed[tempIndex].clients[tempClient] }
                        </p>
                    </td>
                );
            } else {
                headerClient.push(
                    <td className="clients-header-client-cell" key={ "header-client-" + i }>
                        <p className="clients-header-client">
                            { dataKeyed[tempIndex].clients[tempClient] }
                        </p>
                    </td>
                );
            }
        }
        return headerClient;
    }

    function renderHeaderMobile() {
        let headerMobile = [];
        let index = [];
        let clientIndex = [];

        // Generate 3 random category indices, then generate 3 random client indices
        // within each category
        index = randomGen(ITEMS_PER_ROW, dataKeyed.length);
        for (let i = 0; i < index.length; i++) {
            let tempIndex = index[i];
            clientIndex.push(randomGen(1, dataKeyed[tempIndex].clients.length));
        }

        for (let i = 0; i < ITEMS_PER_ROW; i++) {
            let category = index[i];
            let client = clientIndex[i];
            headerMobile.push(
                <thead className="clients-header-container-mobile" key={ "header-mobile-" + i }>
                    <tr>
                        <th className="clients-header">
                            <NavLink className="clients-no-decor" to={ dataKeyed[category].link }>
                                <p className="clients-header-category">{ dataKeyed[category].name }</p>
                            </NavLink>
                        </th>
                    </tr>
                    <tr>
                        <td className="clients-header-cell">
                            <img className="clients-header-img" src={ dataKeyed[category].icon[client] } alt={ dataKeyed[category].icon[client] }></img>
                        </td>
                    </tr>
                    <tr>
                        <td className="clients-header-client-cell">
                            <p className="clients-header-client">{ dataKeyed[category].clients[client] }</p>
                        </td>
                    </tr>
                </thead>
            )
        }
        return headerMobile;
    }

    return (
        <div className="clients-container-main">
            <div className="clients-banner-container">
                <img src={ clientsBanner } alt={ clientsBanner } className="clients-banner" />
                <div className="clients-banner-text">   
                    <div className="clients-banner-half-span">
                        <h1 >Our Clients and Customers</h1>
                        <p>
                            Suncan Communications Corporation provides essential solutions to 
                            services in a wide variety of industries. Both new and existing 
                            clients can contact us at any time of the day and expect a swift 
                            reply with the next steps to proceed with 
                            addressing whatever server and database operations need work.
                        </p>
                    </div>
                </div>
            </div>
            <div className="clients-shifting-container">
                <table className="clients-header-table" key="clients-header-table">
                    { renderHeader() }
                    { renderHeaderMobile() }
                </table>
                <table className="clients-table" key="clients-table">
                    <tbody className="clients-table-body">
                        { renderClientList(ITEMS_PER_ROW, "desktop") }
                    </tbody>
                    <tbody className="clients-table-body-mobile">
                        { renderClientList(ITEMS_PER_ROW_MOBILE, "mobile") }
                    </tbody>
                </table>
            </div>
            <hr className="clients-spacer" />
        </div>
    )
}

export default Clients
