import React, { useState, useRef, useEffect } from 'react'
import './Admin.css'
import { useAuth } from '../components/AuthContext'
import BlogList from '../components/BlogList'

import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'
import db from '../firebase'
import {    
	query,
    collection,
	onSnapshot,
	addDoc
} from 'firebase/firestore'
import { storage } from '../firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'

const Admin = () => {


	const [selectedFile, setSelectedFile] = useState(null);
	const fileChange = (e) => {
		if (!e.target.files.length) {
			setSelectedFile(null);

			return;
		}
		setSelectedFile(e.target.files[0]);
	}

	const [posts, setPosts] = useState(null);
    useEffect (() => {
        // Fetch list of blog posts from database and attach listener
		const q = query(collection(db, 'blog-test'));
		const temp = [];
		const unsub = onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				temp.push({ ...doc.data(), id: doc.id })
			})
			setPosts(sortPosts(temp));
		})


        const sortPosts = (temp) => {
            const sorted = temp.sort((a, b) => {
                return moment(b.date).diff(a.date);
            });
            return sorted;
        }

		return () => {
			unsub();
		}
		
    }, [])

	const emailRef = useRef();
    const passwordRef = useRef();
    const { login, currentUser, logout } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

	const handleSubmit = async (e)  => {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
        } catch(error) {
            setError('Incorrect Credentials');
            console.log(error);
            setLoading(false);  // THIS COULD BE A PROBLEM
        }
		setLoading(false);
    }

	const handleLogout = async () => {
		setError('');

		try {
			await logout()
		} catch(error) {
			setError('Failed to log out');
			console.log(error);
		}
	}

	const [clicked, setClicked] = useState(false);
	const handleClick = (e) => {
        e.preventDefault();
        setClicked(!clicked);
    }

	const currentDate = moment().format('M/D/YY');

	const [entry, setEntry] = useState({
		author: '',
		date: currentDate,
		img: '',
		summary: '',
		title: '',
		url: ''
	});
	const handleChange = (e) => {
        e.preventDefault();
        setEntry((fields) => ({ ...fields, [e.target.id]: e.target.value}));
    }


	const toastId = React.useRef(null);

	const sendEntry = async (e) => {
		e.preventDefault();

		if (entry === {
			author: '',
			date: currentDate,
			img: '',
			summary: '',
			title: '',
			url: ''
		}) return;

		const newDoc = await addDoc(collection(db, 'blog-test'), {
			author: entry.author,
			date: entry.date,
			hasDoc: (selectedFile ? true : false),
			img: entry.img,
			summary: entry.summary,
			title: entry.title,
			url: entry.url
		})

		if (selectedFile) {
			const storageRef = ref(storage, 'blog_docs_test/' + entry.title.replaceAll(' ', '_') + '-_-' + newDoc.id);
			// uploadBytes(storageRef, selectedFile).then(() => {
			// 	console.log(selectedFile);
			// });

			toastId.current = toast.loading('Document is being uploaded');
			const uploadTask = uploadBytesResumable(storageRef, selectedFile);
			uploadTask.on('state_changed',
				(snapshot) => {
				},
				(error) => {
					console.log(error);
				},
				() => {
					toast.update(toastId.current, { render: 'Upload success', type: 'success', isLoading: false });
					window.location.reload();
				}
			);
		} else {
			window.location.reload();
		}
	}

	return (
		<div className='admin-container-main'>
			<div className='admin-shifting-container'>
				{ clicked ? 
					<div className='bloglist-container'>
						<div className='bloglist-menu-background' />
						<div className='bloglist-edit-menu'>
							<div className='bloglist-top-row'>
								<FontAwesomeIcon 
									icon={ faXmark } 
									onClick={ handleClick }
									className='bloglist-x-symbol'
								/>
								<p>Add Post</p>
							</div>
							<form className='bloglist-edit-form' onSubmit={ sendEntry }>
								<span>Title*</span>
								<input
									onChange={ handleChange }
									value={ entry.title }
									id='title'
									type='text'
									required
								/>
								<span>Author</span>
								<input
									onChange={ handleChange }
									value={ entry.author }
									id='author'
									type='text'
								/>
								<span>Date*</span>
								<input
									onChange={ handleChange }
									value={ entry.date }
									id='date'
									type='text'
									required
								/>
								<span>Image</span>
								<input
									onChange={ handleChange }
									value={ entry.img }
									id='img'
									type='text'
								/>
								<span>Website Link (include https://www. in link)</span>
								<input
									onChange={ handleChange }
									value={ entry.url }
									id='url'
									type='text'
									
								/>
								<span>PDF Upload</span>
								<input 
									type='file' 
									name='file' 
									onChange={ fileChange } 
								/>
								<span>Summary</span>
								<textarea
									onChange={ handleChange }
									value={ entry.summary }
									id='summary'
									type='text'
									rows='5'
								/>
								<p>* fields are required</p>
								<button type='submit' className='bloglist-submit'>Add Post</button>
							</form>
						</div>
					</div>
					:
					<div />
				}
				
				{ currentUser ?  
					<div className='admin-menu-container'>
						<div className='admin-menu-top-row'>
							Admin Panel
							<button onClick={ handleLogout } className='admin-menu-logout'>Log Out</button>
						</div>
						<p>Blog Post List</p>
						<div className='admin-blog-list'>
							{ posts?.map(post => (
								post.id === 'base-doc' ?
								<div key='base-doc' />
								:
								<BlogList
									author={ post.author }
									date={ post.date }
									hasDoc={ post.hasDoc }
									img={ post.img }
									summary={ post.summary }
									title={ post.title }
									url={ post.url }
									key={ post.id }
									id={ post.id }
								/>
                        	))}
						</div>
						<button onClick={ handleClick } className='admin-add-button'>Add Blog Post</button>
					</div> 
					:
					<div className='admin-login-form'>
						<h2>Admin Login</h2>
						{error && <h1 className='danger'>{ error }</h1> }
						<form onSubmit={ handleSubmit }>
								Email
								<input
									id='email' 
									ref={ emailRef } 
									type='email'
									required
								/>
								Password
								<input 
									id='password'
									ref={ passwordRef } 
									type='password'
									required
								/>
							<button 
								className='login__button'
								type='submit'
								disabled={ loading }
							>
							Log In
							</button>
						</form>
					</div>
				}
				<ToastContainer 
					hideProgressBar={ true }
					limit={ 1 }
					transition={ Slide }
					autoClose={ 3000 }
					position={ toast.POSITION.TOP_CENTER }
            	/>
				
			</div>
		</div>
	)
}

export default Admin 